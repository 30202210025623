/**
 * This is a hard-coded mapping of the ARA3 annotations to human readable name.
 *
 * This data is derived from http://api.brain-map.org/api/v2/structure_graph_download/1.json .
 *
 * TODO: We should write a parser for JSON ontologies.
 *
 */


export class AraAtlas {
    constructor() {
        console.log('Atlas created!');
    }

    public getNameForId(id: number) {
        return this.ara_id.has(id) ? this.ara_id.get(id) : 'UNKNOWN';
    }

    ara_id: Map<number, string> = new Map([
        [1, '1: TMv: Tuberomammillary nucleus, ventral part'],
        [2, '2: SSp-m6b: Primary somatosensory area, mouth, layer 6b'],
        [3, '3: sec: secondary fissure'],
        [4, '4: IC: Inferior colliculus'],
        [6, '6: int: internal capsule'],
        [7, '7: PSV: Principal sensory nucleus of the trigeminal'],
        [9, '9: SSp-tr6a: Primary somatosensory area, trunk, layer 6a'],
        [10, '10: SCig: Superior colliculus, motor related, intermediate gray layer'],
        [11, '11: plf: posterolateral fissure'],
        [12, '12: IF: Interfascicular nucleus raphe'],
        [14, '14: im: internal medullary lamina of the thalamus'],
        [15, '15: PT: Parataenial nucleus'],
        [16, '16: 6b: Layer 6b, isocortex'],
        [17, '17: SCiw: Superior colliculus, motor related, intermediate white layer'],
        [18, '18: nf: nodular fissure'],
        [19, '19: IG: Induseum griseum'],
        [20, '20: ENTl2: Entorhinal area, lateral part, layer 2'],
        [21, '21: lotg: lateral olfactory tract, general'],
        [22, '22: PTLp: Posterior parietal association areas'],
        [23, '23: AAA: Anterior amygdalar area'],
        [25, '25: sif: simple fissure'],
        [26, '26: SCdg: Superior colliculus, motor related, deep gray layer'],
        [27, '27: IGL: Intergeniculate leaflet of the lateral geniculate complex'],
        [28, '28: ENTl6a: Entorhinal area, lateral part, layer 6a'],
        [29, '29: sttl: lateral spinothalamic tract'],
        [30, '30: PVa: Periventricular hypothalamic nucleus, anterior part'],
        [31, '31: ACA: Anterior cingulate area'],
        [33, '33: VISp6a: Primary visual area, layer 6a'],
        [34, '34: icf: intercrural fissure'],
        [35, '35: III: Oculomotor nucleus'],
        [36, '36: GU1: Gustatory areas, layer 1'],
        [37, '37: lab: longitudinal association bundle'],
        [38, '38: PVH: Paraventricular hypothalamic nucleus'],
        [39, '39: ACAd: Anterior cingulate area, dorsal part'],
        [41, '41: VISpm2/3: posteromedial visual area, layer 2/3'],
        [42, '42: SCdw: Superior colliculus, motor related, deep white layer'],
        [43, '43: apf: ansoparamedian fissure'],
        [44, '44: ILA: Infralimbic area'],
        [45, '45: SPVOrdm: Spinal nucleus of the trigeminal, oral part, rostral dorsomedial part'],
        [46, '46: mfbsma: mammillary related'],
        [47, '47: PVHam: Paraventricular hypothalamic nucleus, magnocellular division, anterior magnocellular part'],
        [48, '48: ACAv: Anterior cingulate area, ventral part'],
        [49, '49: ipf: intraparafloccular fissure'],
        [50, '50: PRC: Precommissural nucleus'],
        [51, '51: ILM: Intralaminar nuclei of the dorsal thalamus'],
        [52, '52: ENTl3: Entorhinal area, lateral part, layer 3'],
        [53, '53: SPVOmdmd: Spinal nucleus of the trigeminal, oral part, middle dorsomedial part, dorsal zone'],
        [54, '54: mfb: medial forebrain bundle'],
        [55, '55: PVHap: Paraventricular hypothalamic nucleus, parvicellular division, anterior parvicellular part'],
        [56, '56: ACB: Nucleus accumbens'],
        [57, '57: pms: paramedian sulcus'],
        [58, '58: MT: Medial terminal nucleus of the accessory optic tract'],
        [59, '59: IMD: Intermediodorsal nucleus of the thalamus'],
        [60, '60: ENTl6b: Entorhinal area, lateral part, layer 6b'],
        [61, '61: SPVOmdmv: Spinal nucleus of the trigeminal, oral part, middle dorsomedial part, ventral zone'],
        [62, '62: mlf: medial longitudinal fascicle'],
        [63, '63: PVHd: Paraventricular hypothalamic nucleus, descending division'],
        [64, '64: AD: Anterodorsal nucleus'],
        [65, '65: pfs: parafloccular sulcus'],
        [66, '66: LT: Lateral terminal nucleus of the accessory optic tract'],
        [67, '67: INC: Interstitial nucleus of Cajal'],
        [68, '68: FRP1: Frontal pole, layer 1'],
        [69, '69: SPVOvl: Spinal nucleus of the trigeminal, oral part, ventrolateral part'],
        [70, '70: mfbsm: midbrain related'],
        [71, '71: PVHm: Paraventricular hypothalamic nucleus, magnocellular division'],
        [72, '72: ADP: Anterodorsal preoptic nucleus'],
        [73, '73: VS: ventricular systems'],
        [74, '74: VISl6a: Lateral visual area, layer 6a'],
        [75, '75: DT: Dorsal terminal nucleus of the accessory optic tract'],
        [76, '76: INV: Interstitial nucleus of the vestibular nerve'],
        [77, '77: SPVOcdm: Spinal nucleus of the trigeminal, oral part, caudal dorsomedial part'],
        [78, '78: mcp: middle cerebellar peduncle'],
        [79, '79: PVHmm: Paraventricular hypothalamic nucleus, magnocellular division, medial magnocellular part'],
        [80, '80: AHA: Anterior hypothalamic area'],
        [81, '81: VL: lateral ventricle'],
        [82, '82: NLLd: Nucleus of the lateral lemniscus, dorsal part'],
        [83, '83: IO: Inferior olivary complex'],
        [84, '84: PL6a: Prelimbic area, layer 6a'],
        [85, '85: sct: spinocerebellar tract'],
        [86, '86: mtc: middle thalamic commissure'],
        [87, '87: PVHmpd: Paraventricular hypothalamic nucleus, parvicellular division, medial parvicellular part, dorsal zone'],
        [88, '88: AHN: Anterior hypothalamic nucleus'],
        [89, '89: RC: rhinocele'],
        [90, '90: NLLh: Nucleus of the lateral lemniscus, horizontal part'],
        [91, '91: IP: Interposed nucleus'],
        [92, '92: ENTl4: Entorhinal area, lateral part, layer 4'],
        [93, '93: moV: motor root of the trigeminal nerve'],
        [94, '94: PVHp: Paraventricular hypothalamic nucleus, parvicellular division'],
        [95, '95: AI: Agranular insular area'],
        [96, '96: DCO: Dorsal cochlear nucleus'],
        [97, '97: TEa1: Temporal association areas, layer 1'],
        [98, '98: SEZ: subependymal zone'],
        [99, '99: NLLv: Nucleus of the lateral lemniscus, ventral part'],
        [100, '100: IPN: Interpeduncular nucleus'],
        [101, '101: VCO: Ventral cochlear nucleus'],
        [102, '102: nst: nigrostriatal tract'],
        [103, '103: PVHpm: Paraventricular hypothalamic nucleus, magnocellular division, posterior magnocellular part'],
        [104, '104: AId: Agranular insular area, dorsal part'],
        [105, '105: SOCm: Superior olivary complex, medial part'],
        [106, '106: ISN: Inferior salivatory nucleus'],
        [107, '107: MO1: Somatomotor areas, Layer 1'],
        [108, '108: chpl: choroid plexus'],
        [109, '109: ntt: nigrothalamic fibers'],
        [110, '110: PVHpv: Paraventricular hypothalamic nucleus, parvicellular division, periventricular part'],
        [111, '111: AIp: Agranular insular area, posterior part'],
        [112, '112: CNlam: Granular lamina of the cochlear nuclei'],
        [113, '113: SSp-ll2/3: Primary somatosensory area, lower limb, layer 2/3'],
        [114, '114: SOCl: Superior olivary complex, lateral part'],
        [115, '115: IV: Trochlear nucleus'],
        [116, '116: chfl: choroid fissure'],
        [117, '117: och: optic chiasm'],
        [118, '118: PVi: Periventricular hypothalamic nucleus, intermediate part'],
        [119, '119: AIv: Agranular insular area, ventral part'],
        [120, '120: AIp1: Agranular insular area, posterior part, layer 1'],
        [121, '121: VISl6b: Lateral visual area, layer 6b'],
        [122, '122: POR: Superior olivary complex, periolivary region'],
        [123, '123: KF: Koelliker-Fuse subnucleus'],
        [124, '124: IVF: interventricular foramen'],
        [125, '125: opt: optic tract'],
        [126, '126: PVp: Periventricular hypothalamic nucleus, posterior part'],
        [127, '127: AM: Anteromedial nucleus'],
        [128, '128: MRN: Midbrain reticular nucleus'],
        [129, '129: V3: third ventricle'],
        [130, '130: CSm: Superior central nucleus raphe, medial part'],
        [131, '131: LA: Lateral amygdalar nucleus'],
        [132, '132: PL6b: Prelimbic area, layer 6b'],
        [133, '133: PVpo: Periventricular hypothalamic nucleus, preoptic part'],
        [134, '134: ptf: pallidotegmental fascicle'],
        [135, '135: AMB: Nucleus ambiguus'],
        [136, '136: IRN: Intermediate reticular nucleus'],
        [137, '137: CSl: Superior central nucleus raphe, lateral part'],
        [138, '138: LAT: Lateral group of the dorsal thalamus'],
        [139, '139: ENTl5: Entorhinal area, lateral part, layer 5'],
        [140, '140: AQ: cerebral aqueduct'],
        [141, '141: PVR: Periventricular region'],
        [142, '142: pap: pallidothalamic pathway'],
        [143, '143: AMBv: Nucleus ambiguus, ventral division'],
        [144, '144: OT1-3: Olfactory tubercle, layers 1-3'],
        [145, '145: V4: fourth ventricle'],
        [146, '146: PRNr: Pontine reticular nucleus'],
        [147, '147: LC: Locus ceruleus'],
        [148, '148: GU4: Gustatory areas, layer 4'],
        [149, '149: PVT: Paraventricular nucleus of the thalamus'],
        [150, '150: pvbh: periventricular bundle of the hypothalamus'],
        [151, '151: AOB: Accessory olfactory bulb'],
        [152, '152: PIR1-3: Piriform area, layers 1-3'],
        [153, '153: V4r: lateral recess'],
        [154, '154: PHY: Perihypoglossal nuclei'],
        [155, '155: LD: Lateral dorsal nucleus of thalamus'],
        [156, '156: AUDd6a: Dorsal auditory area, layer 6a'],
        [157, '157: PVZ: Periventricular zone'],
        [158, '158: pc: posterior commissure'],
        [159, '159: AON: Anterior olfactory nucleus'],
        [160, '160: AON1: Anterior olfactory nucleus, layer 1'],
        [161, '161: NIS: Nucleus intercalatus'],
        [162, '162: LDT: Laterodorsal tegmental nucleus'],
        [163, '163: AIp2/3: Agranular insular area, posterior part, layer 2/3'],
        [164, '164: c: central canal, spinal cord/medulla'],
        [165, '165: RAmb: Midbrain raphe nuclei'],
        [166, '166: pmx: premammillary commissure'],
        [167, '167: AONd: Anterior olfactory nucleus, dorsal part'],
        [168, '168: AON2: Anterior olfactory nucleus, layer 2'],
        [169, '169: PRP: Nucleus prepositus'],
        [170, '170: LGd: Dorsal part of the lateral geniculate complex'],
        [171, '171: PL1: Prelimbic area, layer 1'],
        [173, '173: RCH: Retrochiasmatic area'],
        [174, '174: poc: preoptic commissure'],
        [175, '175: AONe: Anterior olfactory nucleus, external part'],
        [177, '177: NR: Nucleus of Roller'],
        [178, '178: LGv: Ventral part of the lateral geniculate complex'],
        [179, '179: ACA6a: Anterior cingulate area, layer 6a'],
        [180, '180: GU2/3: Gustatory areas, layer 2/3'],
        [181, '181: RE: Nucleus of reuniens'],
        [182, '182: php: propriohypothalamic pathways'],
        [183, '183: AONl: Anterior olfactory nucleus, lateral part'],
        [184, '184: FRP: Frontal pole, cerebral cortex'],
        [185, '185: PPYd: Parapyramidal nucleus, deep part'],
        [186, '186: LH: Lateral habenula'],
        [187, '187: GU5: Gustatory areas, layer 5'],
        [188, '188: AOBgl: Accessory olfactory bulb, glomerular layer'],
        [189, '189: RH: Rhomboid nucleus'],
        [190, '190: py: pyramid'],
        [191, '191: AONm: Anterior olfactory nucleus, medial part'],
        [192, '192: COAa1: Cortical amygdalar area, anterior part, layer 1'],
        [193, '193: PPYs: Parapyramidal nucleus, superficial part'],
        [194, '194: LHA: Lateral hypothalamic area'],
        [195, '195: PL2: Prelimbic area, layer 2'],
        [196, '196: AOBgr: Accessory olfactory bulb, granular layer'],
        [197, '197: RL: Rostral linear nucleus raphe'],
        [198, '198: pyd: pyramidal decussation'],
        [199, '199: AONpv: Anterior olfactory nucleus, posteroventral part'],
        [200, '200: COAa2: Cortical amygdalar area, anterior part, layer 2'],
        [201, '201: SSp-bfd2/3: Primary somatosensory area, barrel field, layer 2/3'],
        [202, '202: MV: Medial vestibular nucleus'],
        [203, '203: LIN: Linear nucleus of the medulla'],
        [204, '204: AOBmi: Accessory olfactory bulb, mitral layer'],
        [205, '205: rstl: retriculospinal tract, lateral part'],
        [206, '206: RM: Nucleus raphe magnus'],
        [207, '207: AP: Area postrema'],
        [208, '208: COAa3: Cortical amygdalar area, anterior part, layer 3'],
        [209, '209: LAV: Lateral vestibular nucleus'],
        [210, '210: LM: Lateral mammillary nucleus'],
        [211, '211: ACAd2/3: Anterior cingulate area, dorsal part, layer 2/3'],
        [212, '212: MOBgl: Main olfactory bulb, glomerular layer'],
        [213, '213: rstm: retriculospinal tract, medial part'],
        [214, '214: RN: Red nucleus'],
        [215, '215: APN: Anterior pretectal nucleus'],
        [216, '216: COApl1: Cortical amygdalar area, posterior part, lateral zone, layer 1'],
        [217, '217: SUV: Superior vestibular nucleus'],
        [218, '218: LP: Lateral posterior nucleus of the thalamus'],
        [219, '219: MO2/3: Somatomotor areas, Layer 2/3'],
        [220, '220: MOBgr: Main olfactory bulb, granule layer'],
        [221, '221: rrt: rubroreticular tract'],
        [222, '222: RO: Nucleus raphe obscurus'],
        [223, '223: ARH: Arcuate hypothalamic nucleus'],
        [224, '224: COApl2: Cortical amygdalar area, posterior part, lateral zone, layer 2'],
        [225, '225: SPIV: Spinal vestibular nucleus'],
        [226, '226: LPO: Lateral preoptic area'],
        [227, '227: ACA6b: Anterior cingulate area, layer 6b'],
        [228, '228: MOBipl: Main olfactory bulb, inner plexiform layer'],
        [229, '229: sV: sensory root of the trigeminal nerve'],
        [230, '230: RPA: Nucleus raphe pallidus'],
        [231, '231: AT: Anterior tegmental nucleus'],
        [232, '232: COApl3: Cortical amygdalar area, posterior part, lateral zone, layer 3'],
        [233, '233: VISal5: Anterolateral visual area, layer 5'],
        [234, '234: TEa4: Temporal association areas, layer 4'],
        [235, '235: LRN: Lateral reticular nucleus'],
        [236, '236: MOBmi: Main olfactory bulb, mitral layer'],
        [237, '237: ts: solitary tract'],
        [238, '238: RPO: Nucleus raphe pontis'],
        [239, '239: ATN: Anterior group of the dorsal thalamus'],
        [240, '240: COApm1: Cortical amygdalar area, posterior part, medial zone, layer 1'],
        [241, '241: PTLp2/3: Posterior parietal association areas, layer 2/3'],
        [242, '242: LS: Lateral septal nucleus'],
        [243, '243: AUDd6b: Dorsal auditory area, layer 6b'],
        [244, '244: MOBopl: Main olfactory bulb, outer plexiform layer'],
        [245, '245: scrt: spinocervical tract'],
        [246, '246: RR: Midbrain reticular nucleus, retrorubral area'],
        [247, '247: AUD: Auditory areas'],
        [248, '248: COApm2: Cortical amygdalar area, posterior part, medial zone, layer 2'],
        [249, '249: AUDpo6a: Posterior auditory area, layer 6a'],
        [250, '250: LSc: Lateral septal nucleus, caudal (caudodorsal) part'],
        [251, '251: AUDp2/3: Primary auditory area, layer 2/3'],
        [252, '252: AUDd5: Dorsal auditory area, layer 5'],
        [253, '253: shp: spinohypothalamic pathway'],
        [254, '254: RSP: Retrosplenial area'],
        [255, '255: AV: Anteroventral nucleus of thalamus'],
        [256, '256: COApm3: Cortical amygdalar area, posterior part, medial zone, layer 3'],
        [257, '257: VISpm6a: posteromedial visual area, layer 6a'],
        [258, '258: LSr: Lateral septal nucleus, rostral (rostroventral) part'],
        [259, '259: ENTmv1: Entorhinal area, medial part, ventral zone, layer 1'],
        [260, '260: NLOT1: Nucleus of the lateral olfactory tract, molecular layer'],
        [261, '261: sop: spino-olivary pathway'],
        [262, '262: RT: Reticular nucleus of the thalamus'],
        [263, '263: AVP: Anteroventral preoptic nucleus'],
        [264, '264: ORB1: Orbital area, layer 1'],
        [266, '266: LSv: Lateral septal nucleus, ventral part'],
        [267, '267: DP6a: Dorsal peduncular area, layer 6a'],
        [268, '268: NLOT2: Nucleus of the lateral olfactory tract, pyramidal layer'],
        [269, '269: VISpl2/3: Posterolateral visual area, layer 2/3'],
        [270, '270: srp: spinoreticular pathway'],
        [271, '271: SAG: Nucleus sagulum'],
        [272, '272: AVPV: Anteroventral periventricular nucleus'],
        [274, '274: RSPd6a: Retrosplenial area, dorsal part, layer 6a'],
        [275, '275: LSX: Lateral septal complex'],
        [276, '276: PIR1: Piriform area, molecular layer'],
        [277, '277: stp: spinotectal pathway'],
        [278, '278: sAMY: Striatum-like amygdalar nuclei'],
        [279, '279: RSPagl6b: Retrosplenial area, lateral agranular part, layer 6b'],
        [280, '280: B: Barrington\'s nucleus'],
        [281, '281: VISam1: Anteromedial visual area, layer 1'],
        [283, '283: LTN: Lateral tegmental nucleus'],
        [284, '284: PIR2: Piriform area, pyramidal layer'],
        [285, '285: step: spinotelenchephalic pathway'],
        [286, '286: SCH: Suprachiasmatic nucleus'],
        [287, '287: BAC: Bed nucleus of the anterior commissure'],
        [288, '288: ORBvl2/3: Orbital area, ventrolateral part, layer 2/3'],
        [289, '289: TEa5: Temporal association areas, layer 5'],
        [290, '290: LZ: Hypothalamic lateral zone'],
        [291, '291: PIR3: Piriform area, polymorph layer'],
        [292, '292: BA: Bed nucleus of the accessory olfactory tract'],
        [293, '293: svp: spinovestibular pathway'],
        [294, '294: SCm: Superior colliculus, motor related'],
        [295, '295: BLA: Basolateral amygdalar nucleus'],
        [296, '296: ACAv2/3: Anterior cingulate area, ventral part, layer 2/3'],
        [297, '297: TTd1-4: Taenia tecta, dorsal part, layers 1-4'],
        [298, '298: MA: Magnocellular nucleus'],
        [299, '299: MO5: Somatomotor areas, Layer 5'],
        [300, '300: LGvl: Ventral part of the lateral geniculate complex, lateral zone'],
        [301, '301: st: stria terminalis'],
        [302, '302: SCs: Superior colliculus, sensory related'],
        [303, '303: BLAa: Basolateral amygdalar nucleus, anterior part'],
        [304, '304: PL2/3: Prelimbic area, layer 2/3'],
        [305, '305: VISp6b: Primary visual area, layer 6b'],
        [306, '306: TTv1-3: Taenia tecta, ventral part, layers 1-3'],
        [307, '307: MARN: Magnocellular reticular nucleus'],
        [308, '308: PTLp6a: Posterior parietal association areas, layer 6a'],
        [309, '309: snp: striatonigral pathway'],
        [310, '310: SF: Septofimbrial nucleus'],
        [311, '311: BLAp: Basolateral amygdalar nucleus, posterior part'],
        [312, '312: ENTl4/5: Entorhinal area, lateral part, layer 4/5'],
        [313, '313: MB: Midbrain'],
        [314, '314: AIp6a: Agranular insular area, posterior part, layer 6a'],
        [315, '315: Isocortex: Isocortex'],
        [316, '316: LGvm: Ventral part of the lateral geniculate complex, medial zone'],
        [317, '317: stf: subthalamic fascicle'],
        [318, '318: SG: Supragenual nucleus'],
        [319, '319: BMA: Basomedial amygdalar nucleus'],
        [320, '320: MOp1: Primary motor area, Layer 1'],
        [321, '321: SubG: Subgeniculate nucleus'],
        [322, '322: SSp: Primary somatosensory area'],
        [323, '323: MBmot: Midbrain, motor related'],
        [324, '324: ENTmv2: Entorhinal area, medial part, ventral zone, layer 2'],
        [325, '325: SGN: Suprageniculate nucleus'],
        [326, '326: scp: superior cerebelar peduncles'],
        [327, '327: BMAa: Basomedial amygdalar nucleus, anterior part'],
        [328, '328: AId2/3: Agranular insular area, dorsal part, layer 2/3'],
        [329, '329: SSp-bfd: Primary somatosensory area, barrel field'],
        [330, '330: RSPd6b: Retrosplenial area, dorsal part, layer 6b'],
        [331, '331: MBO: Mammillary body'],
        [332, '332: ASO: Accessory supraoptic group'],
        [333, '333: SH: Septohippocampal nucleus'],
        [334, '334: BMAp: Basomedial amygdalar nucleus, posterior part'],
        [335, '335: PERI6a: Perirhinal area, layer 6a'],
        [336, '336: csc: superior colliculus commissure'],
        [337, '337: SSp-ll: Primary somatosensory area, lower limb'],
        [338, '338: SFO: Subfornical organ'],
        [339, '339: MBsen: Midbrain, sensory related'],
        [340, '340: PTLp6b: Posterior parietal association areas, layer 6b'],
        [341, '341: smd: supramammillary decussation'],
        [342, '342: SI: Substantia innominata'],
        [343, '343: BS: Brain stem'],
        [344, '344: AIp5: Agranular insular area, posterior part, layer 5'],
        [345, '345: SSp-m: Primary somatosensory area, mouth'],
        [346, '346: SSp2/3: Primary somatosensory area, layer 2/3'],
        [347, '347: SBPV: Subparaventricular zone'],
        [348, '348: MBsta: Midbrain, behavioral state related'],
        [349, '349: sup: supraoptic commissures'],
        [350, '350: SLC: Subceruleus nucleus'],
        [351, '351: BST: Bed nuclei of the stria terminalis'],
        [352, '352: ORB5: Orbital area, layer 5'],
        [353, '353: SSp-n: Primary somatosensory area, nose'],
        [354, '354: MY: Medulla'],
        [355, '355: AIp6b: Agranular insular area, posterior part, layer 6b'],
        [356, '356: PST: Preparasubthalamic nucleus'],
        [357, '357: ttp: tectothalamic pathway'],
        [358, '358: SLD: Sublaterodorsal nucleus'],
        [359, '359: BSTa: Bed nuclei of the stria terminalis, anterior division'],
        [360, '360: DP2/3: Dorsal peduncular area, layer 2/3'],
        [361, '361: SSp-tr: Primary somatosensory area, trunk'],
        [362, '362: MD: Mediodorsal nucleus of thalamus'],
        [363, '363: PL5: Prelimbic area, layer 5'],
        [364, '364: PSTN: Parasubthalamic nucleus'],
        [365, '365: tp: thalamic peduncles'],
        [366, '366: SMT: Submedial nucleus of the thalamus'],
        [367, '367: BSTp: Bed nuclei of the stria terminalis, posterior division'],
        [368, '368: PERI6b: Perirhinal area, layer 6b'],
        [369, '369: SSp-ul: Primary somatosensory area, upper limb'],
        [370, '370: MY-mot: Medulla, motor related'],
        [371, '371: ENTmv3: Entorhinal area, medial part, ventral zone, layer 3'],
        [372, '372: ICB: Infracerebellar nucleus'],
        [373, '373: tct: trigeminocerebellar tract'],
        [374, '374: SNc: Substantia nigra, compact part'],
        [375, '375: CA: Ammon\'s horn'],
        [376, '376: COApl1-3: Cortical amygdalar area, posterior part, lateral zone, layers 1-3'],
        [377, '377: VISpl6a: Posterolateral visual area, layer 6a'],
        [378, '378: SSs: Supplemental somatosensory area'],
        [379, '379: MY-sat: Medulla, behavioral state related'],
        [380, '380: cuf: cuneate fascicle'],
        [381, '381: SNr: Substantia nigra, reticular part'],
        [382, '382: CA1: Field CA1'],
        [383, '383: COApm1-3: Cortical amygdalar area, posterior part, medial zone, layers 1-3'],
        [384, '384: IVd: trochlear nerve decussation'],
        [385, '385: VISp: Primary visual area'],
        [386, '386: MY-sen: Medulla, sensory related'],
        [387, '387: ENTl5/6: Entorhinal area, lateral part, layer 5/6'],
        [388, '388: grf: gracile fascicle'],
        [389, '389: sttv: ventral spinothalamic tract'],
        [390, '390: SO: Supraoptic nucleus'],
        [391, '391: CA1slm: Field CA1, stratum lacunosum-moleculare'],
        [392, '392: NLOT1-3: Nucleus of the lateral olfactory tract, layers 1-3'],
        [393, '393: VISpl6b: Posterolateral visual area, layer 6b'],
        [394, '394: VISam: Anteromedial visual area'],
        [395, '395: MDRN: Medullary reticular nucleus'],
        [396, '396: iaf: internal arcuate fibers'],
        [397, '397: vtd: ventral tegmental decussation'],
        [398, '398: SOC: Superior olivary complex'],
        [399, '399: CA1so: Field CA1, stratum oriens'],
        [400, '400: PAA1-3: Piriform-amygdalar area, layers 1-3'],
        [401, '401: VISam4: Anteromedial visual area, layer 4'],
        [402, '402: VISal: Anterolateral visual area'],
        [403, '403: MEA: Medial amygdalar nucleus'],
        [404, '404: oct: olivocerebellar tract'],
        [405, '405: vlt: ventrolateral hypothalamic tract'],
        [406, '406: SPF: Subparafascicular nucleus'],
        [407, '407: CA1sp: Field CA1, pyramidal layer'],
        [408, '408: PAA1: Piriform-amygdalar area, molecular layer'],
        [409, '409: VISl: Lateral visual area'],
        [410, '410: rct: reticulocerebellar tract'],
        [411, '411: MEAad: Medial amygdalar nucleus, anterodorsal part'],
        [412, '412: ORBl2/3: Orbital area, lateral part, layer 2/3'],
        [413, '413: vVIIIn: vestibular nerve'],
        [414, '414: SPFm: Subparafascicular nucleus, magnocellular part'],
        [415, '415: CA1sr: Field CA1, stratum radiatum'],
        [416, '416: PAA2: Piriform-amygdalar area, pyramidal layer'],
        [417, '417: VISrl: Rostrolateral visual area'],
        [418, '418: MEAav: Medial amygdalar nucleus, anteroventral part'],
        [419, '419: ENTmv4: Entorhinal area, medial part, ventral zone, layer 4'],
        [420, '420: db: precommissural fornix diagonal band'],
        [421, '421: VISl1: Lateral visual area, layer 1'],
        [422, '422: SPFp: Subparafascicular nucleus, parvicellular part'],
        [423, '423: CA2: Field CA2'],
        [424, '424: PAA3: Piriform-amygdalar area, polymorph layer'],
        [425, '425: VISpl: Posterolateral visual area'],
        [426, '426: MEApd: Medial amygdalar nucleus, posterodorsal part'],
        [427, '427: ECT2/3: Ectorhinal area/Layer 2/3'],
        [428, '428: mct: medial corticohypothalamic tract'],
        [429, '429: SPVC: Spinal nucleus of the trigeminal, caudal part'],
        [430, '430: RSPv2/3: Retrosplenial area, ventral part, layer 2/3'],
        [431, '431: CA2slm: Field CA2, stratum lacunosum-moleculare'],
        [432, '432: NC: Nucleus circularis'],
        [433, '433: VISam5: Anteromedial visual area, layer 5'],
        [434, '434: RSPd2/3: Retrosplenial area, dorsal part, layer 2/3'],
        [435, '435: MEApv: Medial amygdalar nucleus, posteroventral part'],
        [436, '436: fx: columns of the fornix'],
        [437, '437: SPVI: Spinal nucleus of the trigeminal, interpolar part'],
        [438, '438: CA2so: Field CA2, stratum oriens'],
        [439, '439: PVHdp: Paraventricular hypothalamic nucleus, descending division, dorsal parvicellular part'],
        [440, '440: ORBl6a: Orbital area, lateral part, layer 6a'],
        [441, '441: VISam6b: Anteromedial visual area, layer 6b'],
        [442, '442: RSPd1: Retrosplenial area, dorsal part, layer 1'],
        [443, '443: dhc: dorsal hippocampal commissure'],
        [444, '444: MED: Medial group of the dorsal thalamus'],
        [445, '445: SPVO: Spinal nucleus of the trigeminal, oral part'],
        [446, '446: CA2sp: Field CA2, pyramidal layer'],
        [447, '447: PVHf: Paraventricular hypothalamic nucleus, descending division, forniceal part'],
        [448, '448: ORBl1: Orbital area, lateral part, layer 1'],
        [449, '449: vhc: ventral hippocampal commissure'],
        [450, '450: SSp-ul1: Primary somatosensory area, upper limb, layer 1'],
        [451, '451: BLAv: Basolateral amygdalar nucleus, ventral part'],
        [452, '452: MEPO: Median preoptic nucleus'],
        [453, '453: SS: Somatosensory areas'],
        [454, '454: CA2sr: Field CA2, stratum radiatum'],
        [455, '455: PVHlp: Paraventricular hypothalamic nucleus, descending division, lateral parvicellular part'],
        [456, '456: AUDpo6b: Posterior auditory area, layer 6b'],
        [457, '457: VIS6a: Visual areas, layer 6a'],
        [458, '458: OT1: Olfactory tubercle, molecular layer'],
        [459, '459: aolt: accessory olfactory tract'],
        [460, '460: MEV: Midbrain trigeminal nucleus'],
        [461, '461: SSp-tr6b: Primary somatosensory area, trunk, layer 6b'],
        [462, '462: SSN: Superior salivatory nucleus'],
        [463, '463: CA3: Field CA3'],
        [464, '464: PVHmpv: Paraventricular hypothalamic nucleus, descending division, medial parvicellular part, ventral zone'],
        [465, '465: OT2: Olfactory tubercle, pyramidal layer'],
        [466, '466: alv: alveus'],
        [467, '467: MEZ: Hypothalamic medial zone'],
        [468, '468: ENTm2a: Entorhinal area, medial part, dorsal zone, layer 2a'],
        [469, '469: VISpm6b: posteromedial visual area, layer 6b'],
        [470, '470: STN: Subthalamic nucleus'],
        [471, '471: CA3slm: Field CA3, stratum lacunosum-moleculare'],
        [472, '472: MEApd-a: Medial amygdalar nucleus, posterodorsal part, sublayer a'],
        [473, '473: OT3: Olfactory tubercle, polymorph layer'],
        [474, '474: ab: angular path'],
        [475, '475: MG: Medial geniculate complex'],
        [476, '476: ORB6a: Orbital area, layer 6a'],
        [477, '477: STR: Striatum'],
        [478, '478: SSp-ll6a: Primary somatosensory area, lower limb, layer 6a'],
        [479, '479: CA3slu: Field CA3, stratum lucidum'],
        [480, '480: MEApd-b: Medial amygdalar nucleus, posterodorsal part, sublayer b'],
        [481, '481: isl: Islands of Calleja'],
        [482, '482: bic: brachium of the inferior colliculus'],
        [483, '483: MH: Medial habenula'],
        [484, '484: ORBm1: Orbital area, medial part, layer 1'],
        [485, '485: STRd: Striatum dorsal region'],
        [486, '486: CA3so: Field CA3, stratum oriens'],
        [487, '487: MEApd-c: Medial amygdalar nucleus, posterodorsal part, sublayer c'],
        [488, '488: ORBl6b: Orbital area, lateral part, layer 6b'],
        [489, '489: islm: Major island of Calleja'],
        [490, '490: bct: bulbocerebellar tract'],
        [491, '491: MM: Medial mammillary nucleus'],
        [492, '492: ORB2/3: Orbital area, layer 2/3'],
        [493, '493: STRv: Striatum ventral region'],
        [494, '494: SCig-a: Superior colliculus, motor related, intermediate gray layer, sublayer a'],
        [495, '495: CA3sp: Field CA3, pyramidal layer'],
        [496, '496: DP1: Dorsal peduncular area, layer 1'],
        [497, '497: VIS6b: Visual areas, layer 6b'],
        [498, '498: BSTam: Bed nuclei of the stria terminalis, anterior division, anteromedial area'],
        [499, '499: cct: cuneocerebellar tract'],
        [500, '500: MO: Somatomotor areas'],
        [501, '501: VISpm4: posteromedial visual area, layer 4'],
        [502, '502: SUB: Subiculum'],
        [503, '503: SCig-b: Superior colliculus, motor related, intermediate gray layer, sublayer b'],
        [504, '504: CA3sr: Field CA3, stratum radiatum'],
        [505, '505: BSTdm: Bed nuclei of the stria terminalis, anterior division, dorsomedial nucleus'],
        [506, '506: das: dorsal acoustic stria'],
        [507, '507: MOB: Main olfactory bulb'],
        [508, '508: ENTm2b: Entorhinal area, medial part, dorsal zone, layer 2b'],
        [509, '509: SUBd: Subiculum, dorsal part'],
        [510, '510: SSp-ll6b: Primary somatosensory area, lower limb, layer 6b'],
        [511, '511: SCig-c: Superior colliculus, motor related, intermediate gray layer, sublayer c'],
        [512, '512: CB: Cerebellum'],
        [513, '513: BSTfu: Bed nuclei of the stria terminalis, anterior division, fusiform nucleus'],
        [514, '514: dc: dorsal column'],
        [515, '515: MPN: Medial preoptic nucleus'],
        [516, '516: ORB6b: Orbital area, layer 6b'],
        [517, '517: TR1-3: Postpiriform transition area, layers 1-3'],
        [518, '518: SUBv: Subiculum, ventral part'],
        [519, '519: CBN: Cerebellar nuclei'],
        [520, '520: AUDv6a: Ventral auditory area, layer 6a'],
        [521, '521: BSTmg: Bed nuclei of the stria terminalis, anterior division, magnocellular nucleus'],
        [522, '522: dcm: dorsal commissure of the spinal cord'],
        [523, '523: MPO: Medial preoptic area'],
        [524, '524: ORBm2: Orbital area, medial part, layer 2'],
        [525, '525: SUM: Supramammillary nucleus'],
        [526, '526: ENTm1: Entorhinal area, medial part, dorsal zone, layer 1'],
        [527, '527: AUDd1: Dorsal auditory area, layer 1'],
        [528, '528: CBX: Cerebellar cortex'],
        [529, '529: BSTv: Bed nuclei of the stria terminalis, anterior division, ventral nucleus'],
        [530, '530: df: dorsal fornix'],
        [531, '531: MPT: Medial pretectal area'],
        [532, '532: PTLp1: Posterior parietal association areas, layer 1'],
        [533, '533: VISpm: posteromedial visual area'],
        [534, '534: SUT: Supratrigeminal nucleus'],
        [535, '535: DP2: Dorsal peduncular area, layer 2'],
        [536, '536: CEA: Central amygdalar nucleus'],
        [537, '537: BSTal: Bed nuclei of the stria terminalis, anterior division, anterolateral area'],
        [538, '538: lotd: dorsal limb'],
        [539, '539: MRNm: Midbrain reticular nucleus, magnocellular part'],
        [540, '540: PERI1: Perirhinal area, layer 1'],
        [541, '541: TEa: Temporal association areas'],
        [542, '542: RSPv1: Retrosplenial area, ventral part, layer 1'],
        [543, '543: ENTm2: Entorhinal area, medial part, dorsal zone, layer 2'],
        [544, '544: CEAc: Central amygdalar nucleus, capsular part'],
        [545, '545: RSPd4: Retrosplenial area, dorsal part, layer 4'],
        [546, '546: BSTju: Bed nuclei of the stria terminalis, anterior division, juxtacapsular nucleus'],
        [547, '547: dlf: dorsal longitudinal fascicle'],
        [548, '548: MRNmg: Midbrain reticular nucleus, magnocellular part, general'],
        [549, '549: TH: Thalamus'],
        [550, '550: ENTm5/6: Entorhinal area, medial part, dorsal zone, layer 5/6'],
        [551, '551: CEAl: Central amygdalar nucleus, lateral part'],
        [552, '552: PRNv: Pontine reticular nucleus, ventral part'],
        [553, '553: sctd: dorsal spinocerebellar tract'],
        [554, '554: BSTov: Bed nuclei of the stria terminalis, anterior division, oval nucleus'],
        [555, '555: MRNp: Midbrain reticular nucleus, parvicellular part'],
        [556, '556: ILA2/3: Infralimbic area, layer 2/3'],
        [557, '557: TM: Tuberomammillary nucleus'],
        [558, '558: SSp-n1: Primary somatosensory area, nose, layer 1'],
        [559, '559: CEAm: Central amygdalar nucleus, medial part'],
        [560, '560: CNspg: Cochlear nucleus, subpedunclular granular region'],
        [561, '561: VIS2/3: Visual areas, layer 2/3'],
        [562, '562: BSTrh: Bed nuclei of the stria terminalis, anterior division, rhomboid nucleus'],
        [563, '563: dtt: dorsal tegmental tract'],
        [564, '564: MS: Medial septal nucleus'],
        [565, '565: VISpm5: posteromedial visual area, layer 5'],
        [566, '566: TR: Postpiriform transition area'],
        [567, '567: CH: Cerebrum'],
        [568, '568: ACVI: Accessory abducens nucleus'],
        [569, '569: BSTd: Bed nuclei of the stria terminalis, posterior division, dorsal nucleus'],
        [570, '570: dl: dorsolateral fascicle'],
        [571, '571: MTN: Midline group of the dorsal thalamus'],
        [572, '572: ACA1: Anterior cingulate area, layer 1'],
        [573, '573: VISl4: Lateral visual area, layer 4'],
        [574, '574: TRN: Tegmental reticular nucleus'],
        [575, '575: CL: Central lateral nucleus of the thalamus'],
        [576, '576: ACVII: Accessory facial motor nucleus'],
        [577, '577: SSp-ul4: Primary somatosensory area, upper limb, layer 4'],
        [578, '578: BSTpr: Bed nuclei of the stria terminalis, posterior division, principal nucleus'],
        [579, '579: ec: external capsule'],
        [580, '580: NB: Nucleus of the brachium of the inferior colliculus'],
        [581, '581: TRS: Triangular nucleus of septum'],
        [582, '582: ORBm2/3: Orbital area, medial part, layer 2/3'],
        [583, '583: CLA: Claustrum'],
        [584, '584: COApl1-2: Cortical amygdalar area, posterior part, lateral zone, layers 1-2'],
        [585, '585: BSTif: Bed nuclei of the stria terminalis, posterior division, interfascicular nucleus'],
        [586, '586: fpr: fasciculus proprius'],
        [587, '587: ND: Nucleus of Darkschewitsch'],
        [588, '588: ACAv1: Anterior cingulate area, ventral part, layer 1'],
        [589, '589: TT: Taenia tecta'],
        [590, '590: RSPv6a: Retrosplenial area, ventral part, layer 6a'],
        [591, '591: CLI: Central linear nucleus raphe'],
        [592, '592: COApm1-2: Cortical amygdalar area, posterior part, medial zone, layers 1-2'],
        [593, '593: VISp1: Primary visual area, layer 1'],
        [594, '594: BSTtr: Bed nuclei of the stria terminalis, posterior division, transverse nucleus'],
        [595, '595: fr: fasciculus retroflexus'],
        [596, '596: NDB: Diagonal band nucleus'],
        [597, '597: TTd: Taenia tecta, dorsal part'],
        [598, '598: AUDv6b: Ventral auditory area, layer 6b'],
        [599, '599: CM: Central medial nucleus of the thalamus'],
        [600, '600: AUDd2/3: Dorsal auditory area, layer 2/3'],
        [601, '601: VISal6a: Anterolateral visual area, layer 6a'],
        [602, '602: BSTse: Bed nuclei of the stria terminalis, posterior division, strial extension'],
        [603, '603: fi: fimbria'],
        [604, '604: NI: Nucleus incertus'],
        [605, '605: TTv: Taenia tecta, ventral part'],
        [606, '606: RSPv2: Retrosplenial area, ventral part, layer 2'],
        [607, '607: CN: Cochlear nuclei'],
        [608, '608: ORBvl6a: Orbital area, ventrolateral part, layer 6a'],
        [609, '609: SPA: Subparafascicular area'],
        [610, '610: RSPd5: Retrosplenial area, dorsal part, layer 5'],
        [611, '611: hbc: habenular commissure'],
        [612, '612: NLL: Nucleus of the lateral lemniscus'],
        [613, '613: VISl5: Lateral visual area, layer 5'],
        [614, '614: TU: Tuberal nucleus'],
        [615, '615: SNl: Substantia nigra, lateral part'],
        [616, '616: CUN: Cuneiform nucleus'],
        [617, '617: MDc: Mediodorsal nucleus of the thalamus, central part'],
        [618, '618: hc: hippocampal commissures'],
        [619, '619: NLOT: Nucleus of the lateral olfactory tract'],
        [620, '620: ORBm5: Orbital area, medial part, layer 5'],
        [621, '621: V: Motor nucleus of trigeminal'],
        [622, '622: RSPv6b: Retrosplenial area, ventral part, layer 6b'],
        [623, '623: CNU: Cerebral nuclei'],
        [624, '624: IPF: Interpeduncular fossa'],
        [625, '625: SSp-ul5: Primary somatosensory area, upper limb, layer 5'],
        [626, '626: MDl: Mediodorsal nucleus of the thalamus, lateral part'],
        [627, '627: hht: hypothalamohypophysial tract'],
        [628, '628: NOT: Nucleus of the optic tract'],
        [629, '629: VAL: Ventral anterior-lateral complex of the thalamus'],
        [630, '630: ORBl5: Orbital area, lateral part, layer 5'],
        [631, '631: COA: Cortical amygdalar area'],
        [632, '632: DG-sg: Dentate gyrus, granule cell layer'],
        [633, '633: cic: inferior colliculus commissure'],
        [634, '634: NPC: Nucleus of the posterior commissure'],
        [635, '635: PTLp4: Posterior parietal association areas, layer 4'],
        [636, '636: MDm: Mediodorsal nucleus of the thalamus, medial part'],
        [637, '637: VENT: Ventral group of the dorsal thalamus'],
        [638, '638: GU6a: Gustatory areas, layer 6a'],
        [639, '639: COAa: Cortical amygdalar area, anterior part'],
        [640, '640: EV: Efferent vestibular nucleus'],
        [641, '641: ias: intermediate acoustic stria'],
        [642, '642: NTB: Nucleus of the trapezoid body'],
        [643, '643: AUDpo2/3: Posterior auditory area, layer 2/3'],
        [644, '644: MO6a: Somatomotor areas, Layer 6a'],
        [645, '645: VERM: Vermal regions'],
        [646, '646: DP5: Dorsal peduncular area, layer 5'],
        [647, '647: COAp: Cortical amygdalar area, posterior part'],
        [648, '648: MOp5: Primary motor area, Layer 5'],
        [649, '649: VISal6b: Anterolateral visual area, layer 6b'],
        [650, '650: jrb: juxtarestiform body'],
        [651, '651: NTS: Nucleus of the solitary tract'],
        [652, '652: PVHpml: Paraventricular hypothalamic nucleus, magnocellular division, posterior magnocellular part, lateral zone'],
        [653, '653: VI: Abducens nucleus'],
        [654, '654: SSp-n4: Primary somatosensory area, nose, layer 4'],
        [655, '655: COApl: Cortical amygdalar area, posterior part, lateral zone'],
        [656, '656: MOs1: Secondary motor area, layer 1'],
        [657, '657: SSp-m2/3: Primary somatosensory area, mouth, layer 2/3'],
        [658, '658: ll: lateral lemniscus'],
        [659, '659: NTSce: Nucleus of the solitary tract, central part'],
        [660, '660: PVHpmm: Paraventricular hypothalamic nucleus, magnocellular division, posterior magnocellular part, medial zone'],
        [661, '661: VII: Facial motor nucleus'],
        [662, '662: GU6b: Gustatory areas, layer 6b'],
        [663, '663: COApm: Cortical amygdalar area, posterior part, medial zone'],
        [664, '664: ENTm3: Entorhinal area, medial part, dorsal zone, layer 3'],
        [665, '665: lot: lateral olfactory tract, body'],
        [666, '666: NTSco: Nucleus of the solitary tract, commissural part'],
        [667, '667: FRP2/3: Frontal pole, layer 2/3'],
        [668, '668: DMHa: Dorsomedial nucleus of the hypothalamus, anterior part'],
        [669, '669: VIS: Visual areas'],
        [670, '670: SSp-tr2/3: Primary somatosensory area, trunk, layer 2/3'],
        [671, '671: RSPagl1: Retrosplenial area, lateral agranular part, layer 1'],
        [672, '672: CP: Caudoputamen'],
        [673, '673: mp: mammillary peduncle'],
        [674, '674: NTSge: Nucleus of the solitary tract, gelatinous part'],
        [675, '675: AIv6a: Agranular insular area, ventral part, layer 6a'],
        [676, '676: DMHp: Dorsomedial nucleus of the hypothalamus, posterior part'],
        [677, '677: VISC: Visceral area'],
        [678, '678: AUDd4: Dorsal auditory area, layer 4'],
        [679, '679: CS: Superior central nucleus raphe'],
        [680, '680: ORBvl6b: Orbital area, ventrolateral part, layer 6b'],
        [681, '681: mtg: mammillotegmental tract'],
        [682, '682: NTSl: Nucleus of the solitary tract, lateral part'],
        [683, '683: PTLp5: Posterior parietal association areas, layer 5'],
        [684, '684: DMHv: Dorsomedial nucleus of the hypothalamus, ventral part'],
        [685, '685: VM: Ventral medial nucleus of the thalamus'],
        [686, '686: SSp6a: Primary somatosensory area, layer 6a'],
        [687, '687: RSPv5: Retrosplenial area, ventral part, layer 5'],
        [688, '688: CTX: Cerebral cortex'],
        [689, '689: VLPO: Ventrolateral preoptic nucleus'],
        [690, '690: mtt: mammillothalamic tract'],
        [691, '691: NTSm: Nucleus of the solitary tract, medial part'],
        [692, '692: PERI5: Perirhinal area, layer 5'],
        [693, '693: VMH: Ventromedial hypothalamic nucleus'],
        [694, '694: AIv2/3: Agranular insular area, ventral part, layer 2/3'],
        [695, '695: CTXpl: Cortical plate'],
        [696, '696: AUDpo1: Posterior auditory area, layer 1'],
        [697, '697: ml: medial lemniscus'],
        [698, '698: OLF: Olfactory areas'],
        [699, '699: AIv6b: Agranular insular area, ventral part, layer 6b'],
        [700, '700: AHNa: Anterior hypothalamic nucleus, anterior part'],
        [701, '701: VNC: Vestibular nuclei'],
        [702, '702: SSp-n5: Primary somatosensory area, nose, layer 5'],
        [703, '703: CTXsp: Cortical subplate'],
        [704, '704: AIv1: Agranular insular area, ventral part, layer 1'],
        [705, '705: mtV: midbrain tract of the trigeminal nerve'],
        [706, '706: OP: Olivary pretectal nucleus'],
        [707, '707: ILA1: Infralimbic area, layer 1'],
        [708, '708: AHNc: Anterior hypothalamic nucleus, central part'],
        [709, '709: VP: Ventral posterior complex of the thalamus'],
        [710, '710: VIn: abducens nerve'],
        [711, '711: CU: Cuneate nucleus'],
        [712, '712: ENTm4: Entorhinal area, medial part, dorsal zone, layer 4'],
        [713, '713: per: perforant path'],
        [714, '714: ORB: Orbital area'],
        [715, '715: ENTl2a: Entorhinal area, lateral part, layer 2a'],
        [716, '716: AHNd: Anterior hypothalamic nucleus, dorsal part'],
        [717, '717: XIn: accessory spinal nerve'],
        [718, '718: VPL: Ventral posterolateral nucleus of the thalamus'],
        [719, '719: SSp6b: Primary somatosensory area, layer 6b'],
        [720, '720: DCN: Dorsal column nuclei'],
        [721, '721: VISp4: Primary visual area, layer 4'],
        [722, '722: pvbt: periventricular bundle of the thalamus'],
        [723, '723: ORBl: Orbital area, lateral part'],
        [724, '724: AHNp: Anterior hypothalamic nucleus, posterior part'],
        [725, '725: VPLpc: Ventral posterolateral nucleus of the thalamus, parvicellular part'],
        [726, '726: DG: Dentate gyrus'],
        [727, '727: ENTm5: Entorhinal area, medial part, dorsal zone, layer 5'],
        [728, '728: arb: arbor vitae'],
        [729, '729: TEa6a: Temporal association areas, layer 6a'],
        [730, '730: PIS: pineal stalk'],
        [731, '731: ORBm: Orbital area, medial part'],
        [732, '732: Mmme: Medial mammillary nucleus, median part'],
        [733, '733: VPM: Ventral posteromedial nucleus of the thalamus'],
        [734, '734: DGcr: Dentate gyrus crest'],
        [735, '735: AUDp1: Primary auditory area, layer 1'],
        [736, '736: ctb: central tegmental bundle'],
        [737, '737: fxpo: postcommissural fornix'],
        [738, '738: ORBv: Orbital area, ventral part'],
        [739, '739: ACA5: Anterior cingulate area, layer 5'],
        [740, '740: MPNc: Medial preoptic nucleus, central part'],
        [741, '741: VPMpc: Ventral posteromedial nucleus of the thalamus, parvicellular part'],
        [742, '742: DGcr-mo: Dentate gyrus crest, molecular layer'],
        [743, '743: ENTm6: Entorhinal area, medial part, dorsal zone, layer 6'],
        [744, '744: cbc: cerebellar commissure'],
        [745, '745: fxprg: precommissural fornix, general'],
        [746, '746: ORBvl: Orbital area, ventrolateral part'],
        [747, '747: ILA2: Infralimbic area, layer 2'],
        [748, '748: MPNl: Medial preoptic nucleus, lateral part'],
        [749, '749: VTA: Ventral tegmental area'],
        [750, '750: VISpl1: Posterolateral visual area, layer 1'],
        [751, '751: DGcr-po: Dentate gyrus crest, polymorph layer'],
        [752, '752: cbp: cerebellar peduncles'],
        [753, '753: pm: principal mammillary tract'],
        [754, '754: OT: Olfactory tubercle'],
        [755, '755: AUDv2/3: Ventral auditory area, layer 2/3'],
        [756, '756: MPNm: Medial preoptic nucleus, medial part'],
        [757, '757: VTN: Ventral tegmental nucleus'],
        [758, '758: DGcr-sg: Dentate gyrus crest, granule cell layer'],
        [759, '759: AUDpo4: Posterior auditory area, layer 4'],
        [760, '760: epsc: cerebral nuclei related'],
        [761, '761: VMHa: Ventromedial hypothalamic nucleus, anterior part'],
        [762, '762: phpd: propriohypothalamic pathways, dorsal'],
        [763, '763: OV: Vascular organ of the lamina terminalis'],
        [764, '764: ENTl2b: Entorhinal area, lateral part, layer 2b'],
        [765, '765: x: Nucleus x'],
        [766, '766: DGlb: Dentate gyrus lateral blade'],
        [767, '767: MOs5: Secondary motor area, layer 5'],
        [768, '768: mfbc: cerebrum related'],
        [769, '769: VMHc: Ventromedial hypothalamic nucleus, central part'],
        [770, '770: phpl: propriohypothalamic pathways, lateral'],
        [771, '771: P: Pons'],
        [772, '772: ACAv5: Anterior cingulate area, ventral part, layer 5'],
        [773, '773: XII: Hypoglossal nucleus'],
        [774, '774: RSPagl5: Retrosplenial area, lateral agranular part, layer 5'],
        [775, '775: DGlb-mo: Dentate gyrus lateral blade, molecular layer'],
        [776, '776: cc: corpus callosum'],
        [777, '777: VMHdm: Ventromedial hypothalamic nucleus, dorsomedial part'],
        [778, '778: VISp5: Primary visual area, layer 5'],
        [779, '779: phpm: propriohypothalamic pathways, medial'],
        [780, '780: PA: Posterior amygdalar nucleus'],
        [781, '781: y: Nucleus y'],
        [782, '782: DGlb-po: Dentate gyrus lateral blade, polymorph layer'],
        [783, '783: AId6a: Agranular insular area, dorsal part, layer 6a'],
        [784, '784: cst: corticospinal tract'],
        [785, '785: VMHvl: Ventromedial hypothalamic nucleus, ventrolateral part'],
        [786, '786: TEa6b: Temporal association areas, layer 6b'],
        [787, '787: phpv: propriohypothalamic pathways, ventral'],
        [788, '788: PAA: Piriform-amygdalar area'],
        [789, '789: z: Nucleus z'],
        [790, '790: DGlb-sg: Dentate gyrus lateral blade, granule cell layer'],
        [791, '791: AUDpo5: Posterior auditory area, layer 5'],
        [792, '792: drt: dorsal roots'],
        [793, '793: SSp1: Primary somatosensory area, layer 1'],
        [794, '794: sptV: spinal tract of the trigeminal nerve'],
        [795, '795: PAG: Periaqueductal gray'],
        [796, '796: A13: Dopaminergic A13 group'],
        [797, '797: ZI: Zona incerta'],
        [798, '798: VIIn: facial nerve'],
        [799, '799: DGmb: Dentate gyrus medial blade'],
        [800, '800: AIv5: Agranular insular area, ventral part, layer 5'],
        [801, '801: VIS1: Visual areas, layer 1'],
        [802, '802: sm: stria medullaris'],
        [803, '803: PAL: Pallidum'],
        [804, '804: FF: Fields of Forel'],
        [805, '805: VISpm1: posteromedial visual area, layer 1'],
        [806, '806: SSs2/3: Supplemental somatosensory area, layer 2/3'],
        [807, '807: DGmb-mo: Dentate gyrus medial blade, molecular layer'],
        [808, '808: IXn: glossopharyngeal nerve'],
        [809, '809: PALc: Pallidum, caudal region'],
        [810, '810: ACAv6a: Anterior cingulate area, ventral part, 6a'],
        [811, '811: ICc: Inferior colliculus, central nucleus'],
        [812, '812: dscp: superior cerebellar peduncle decussation'],
        [813, '813: XIIn: hypoglossal nerve'],
        [814, '814: DP: Dorsal peduncular area'],
        [815, '815: DGmb-po: Dentate gyrus medial blade, polymorph layer'],
        [816, '816: AUDp4: Primary auditory area, layer 4'],
        [817, '817: supa: supraoptic commissures, anterior'],
        [818, '818: PALd: Pallidum, dorsal region'],
        [819, '819: ACAv6b: Anterior cingulate area, ventral part, 6b'],
        [820, '820: ICd: Inferior colliculus, dorsal nucleus'],
        [821, '821: VISp2/3: Primary visual area, layer 2/3'],
        [822, '822: RHP: Retrohippocampal region'],
        [823, '823: DGmb-sg: Dentate gyrus medial blade, granule cell layer'],
        [824, '824: mfsbshy: hypothalamus related'],
        [825, '825: supd: supraoptic commissures, dorsal'],
        [826, '826: PALm: Pallidum, medial region'],
        [827, '827: ILA5: Infralimbic area, layer 5'],
        [828, '828: ICe: Inferior colliculus, external nucleus'],
        [829, '829: SUBd-m: Subiculum, dorsal part, molecular layer'],
        [830, '830: DMH: Dorsomedial nucleus of the hypothalamus'],
        [831, '831: AId6b: Agranular insular area, dorsal part, layer 6b'],
        [832, '832: IIIn: oculomotor nerve'],
        [833, '833: supv: supraoptic commissures, ventral'],
        [834, '834: SCzo: Superior colliculus, zonal layer'],
        [835, '835: PALv: Pallidum, ventral region'],
        [836, '836: ECT1: Ectorhinal area/Layer 1'],
        [837, '837: SUBd-sr: Subiculum, dorsal part, stratum radiatum'],
        [838, '838: SSp-n2/3: Primary somatosensory area, nose, layer 2/3'],
        [839, '839: DMX: Dorsal motor nucleus of the vagus nerve'],
        [840, '840: In: olfactory nerve'],
        [841, '841: tb: trapezoid body'],
        [842, '842: SCsg: Superior colliculus, superficial gray layer'],
        [843, '843: PAR: Parasubiculum'],
        [844, '844: MOp6a: Primary motor area, Layer 6a'],
        [845, '845: SUBd-sp: Subiculum, dorsal part, pyramidal layer'],
        [846, '846: DN: Dentate nucleus'],
        [847, '847: AUDp5: Primary auditory area, layer 5'],
        [848, '848: IIn: optic nerve'],
        [849, '849: VISC6b: Visceral area, layer 6b'],
        [850, '850: uf: uncinate fascicle'],
        [851, '851: SCop: Superior colliculus, optic layer'],
        [852, '852: PARN: Parvicellular reticular nucleus'],
        [853, '853: SUBv-m: Subiculum, ventral part, molecular layer'],
        [854, '854: SSp-ul2/3: Primary somatosensory area, upper limb, layer 2/3'],
        [855, '855: rst: retriculospinal tract'],
        [856, '856: DORpm: Thalamus, polymodal association cortex related'],
        [857, '857: VISC6a: Visceral area, layer 6a'],
        [858, '858: vc: ventral commissure of the spinal cord'],
        [859, '859: PAS: Parasolitary nucleus'],
        [860, '860: PBlc: Parabrachial nucleus, lateral division, central lateral part'],
        [861, '861: SUBv-sr: Subiculum, ventral part, stratum radiatum'],
        [862, '862: SSs6a: Supplemental somatosensory area, layer 6a'],
        [863, '863: rust: rubrospinal tract'],
        [864, '864: DORsm: Thalamus, sensory-motor cortex related'],
        [865, '865: SSp4: Primary somatosensory area, layer 4'],
        [866, '866: sctv: ventral spinocerebellar tract'],
        [867, '867: PB: Parabrachial nucleus'],
        [868, '868: PBld: Parabrachial nucleus, lateral division, dorsal lateral part'],
        [869, '869: VISpl4: Posterolateral visual area, layer 4'],
        [870, '870: SUBv-sp: Subiculum, ventral part, pyramidal layer'],
        [871, '871: sst: spinothalamic tract'],
        [872, '872: DR: Dorsal nucleus raphe'],
        [873, '873: SSs1: Supplemental somatosensory area, layer 1'],
        [874, '874: PBG: Parabigeminal nucleus'],
        [875, '875: PBle: Parabrachial nucleus, lateral division, external lateral part'],
        [876, '876: aot: accessory optic tract'],
        [877, '877: tsp: tectospinal pathway'],
        [878, '878: SSp-m1: Primary somatosensory area, mouth, layer 1'],
        [879, '879: RSPd: Retrosplenial area, dorsal part'],
        [880, '880: DTN: Dorsal tegmental nucleus'],
        [881, '881: PBl: Parabrachial nucleus, lateral division'],
        [882, '882: MOp6b: Primary motor area, Layer 6b'],
        [883, '883: PBls: Parabrachial nucleus, lateral division, superior lateral part'],
        [884, '884: amc: amygdalar capsule'],
        [885, '885: tn: terminal nerve'],
        [886, '886: RSPv: Retrosplenial area, ventral part'],
        [887, '887: ECO: Efferent cochlear group'],
        [888, '888: PERI2/3: Perirhinal area, layer 2/3'],
        [889, '889: SSp-n6a: Primary somatosensory area, nose, layer 6a'],
        [890, '890: PBm: Parabrachial nucleus, medial division'],
        [891, '891: PBlv: Parabrachial nucleus, lateral division, ventral lateral part'],
        [892, '892: apd: ansa peduncularis'],
        [893, '893: SSs6b: Supplemental somatosensory area, layer 6b'],
        [894, '894: RSPagl: Retrosplenial area, lateral agranular part'],
        [895, '895: ECT: Ectorhinal area'],
        [896, '896: lfbst: thalamus related'],
        [897, '897: VISC1: Visceral area, layer 1'],
        [898, '898: PCG: Pontine central gray'],
        [899, '899: PBme: Parabrachial nucleus, medial division, external medial part'],
        [900, '900: aco: anterior commissure, olfactory limb'],
        [901, '901: Vn: trigeminal nerve'],
        [902, '902: VISpl5: Posterolateral visual area, layer 5'],
        [903, '903: ECU: External cuneate nucleus'],
        [904, '904: MSC: Medial septal complex'],
        [905, '905: VISal2/3: Anterolateral visual area, layer 2/3'],
        [906, '906: RSPagl6a: Retrosplenial area, lateral agranular part, layer 6a'],
        [907, '907: PCN: Paracentral nucleus'],
        [908, '908: act: anterior commissure, temporal limb'],
        [909, '909: ENT: Entorhinal area'],
        [910, '910: ORBm6a: Orbital area, medial part, layer 6a'],
        [911, '911: IVn: trochlear nerve'],
        [912, '912: LING: Lingula (I)'],
        [913, '913: VIS4: Visual areas, layer 4'],
        [914, '914: PD: Posterodorsal preoptic nucleus'],
        [915, '915: PBmm: Parabrachial nucleus, medial division, medial medial part'],
        [916, '916: bsc: brachium of the superior colliculus'],
        [917, '917: Xn: vagus nerve'],
        [918, '918: ENTl: Entorhinal area, lateral part'],
        [919, '919: ACAd6a: Anterior cingulate area, dorsal part, layer 6a'],
        [920, '920: CENT: Central lobule'],
        [921, '921: SSp5: Primary somatosensory area, layer 5'],
        [922, '922: PERI: Perirhinal area'],
        [923, '923: PBmv: Parabrachial nucleus, medial division, ventral medial part'],
        [924, '924: cpd: cerebal peduncle'],
        [925, '925: vrt: ventral roots'],
        [926, '926: ENTm: Entorhinal area, medial part, dorsal zone'],
        [927, '927: ACAd6b: Anterior cingulate area, dorsal part, layer 6b'],
        [928, '928: CUL: Culmen'],
        [929, '929: SSp-n6b: Primary somatosensory area, nose, layer 6b'],
        [930, '930: PF: Parafascicular nucleus'],
        [931, '931: PG: Pontine gray'],
        [932, '932: cett: cervicothalamic tract'],
        [933, '933: VIIIn: vestibulocochlear nerve'],
        [934, '934: ENTmv: Entorhinal area, medial part, ventral zone'],
        [935, '935: ACAd1: Anterior cingulate area, dorsal part, layer 1'],
        [936, '936: DEC: Declive (VI)'],
        [937, '937: VIS5: Visual areas, layer 5'],
        [938, '938: PGRN: Paragigantocellular reticular nucleus'],
        [939, '939: AMBd: Nucleus ambiguus, dorsal division'],
        [940, '940: cing: cingulum bundle'],
        [941, '941: vsp: vestibulospinal pathway'],
        [942, '942: EP: Endopiriform nucleus'],
        [943, '943: MOp2/3: Primary motor area, Layer 2/3'],
        [944, '944: FOTU: Folium-tuber vermis (VII)'],
        [945, '945: SSp-ul6a: Primary somatosensory area, upper limb, layer 6a'],
        [946, '946: PH: Posterior hypothalamic nucleus'],
        [947, '947: MO6b: Somatomotor areas, Layer 6b'],
        [948, '948: cVIIIn: cochlear nerve'],
        [949, '949: von: vomeronasal nerve'],
        [950, '950: SSp-m4: Primary somatosensory area, mouth, layer 4'],
        [951, '951: PYR: Pyramus (VIII)'],
        [952, '952: EPd: Endopiriform nucleus, dorsal part'],
        [953, '953: PIN: Pineal body'],
        [954, '954: AUDp6a: Primary auditory area, layer 6a'],
        [955, '955: LRNm: Lateral reticular nucleus, magnocellular part'],
        [956, '956: fa: corpus callosum, anterior forceps'],
        [957, '957: UVU: Uvula (IX)'],
        [958, '958: EPI: Epithalamus'],
        [959, '959: AUDv1: Ventral auditory area, layer 1'],
        [960, '960: cbf: cerebellum related fiber tracts'],
        [961, '961: PIR: Piriform area'],
        [962, '962: MOs2/3: Secondary motor area, layer 2/3'],
        [963, '963: LRNp: Lateral reticular nucleus, parvicellular part'],
        [964, '964: ee: corpus callosum, extreme capsule'],
        [965, '965: RSPagl2/3: Retrosplenial area, lateral agranular part, layer 2/3'],
        [966, '966: EPv: Endopiriform nucleus, ventral part'],
        [967, '967: cm: cranial nerves'],
        [968, '968: NOD: Nodulus (X)'],
        [969, '969: ORBvl1: Orbital area, ventrolateral part, layer 1'],
        [970, '970: PGRNd: Paragigantocellular reticular nucleus, dorsal part'],
        [971, '971: fp: corpus callosum, posterior forceps'],
        [972, '972: PL: Prelimbic area'],
        [973, '973: VISl2/3: Lateral visual area, layer 2/3'],
        [974, '974: SSp-m5: Primary somatosensory area, mouth, layer 5'],
        [975, '975: EW: Edinger-Westphal nucleus'],
        [976, '976: CENT2: Lobule II'],
        [977, '977: ECT6a: Ectorhinal area/Layer 6a'],
        [978, '978: PGRNl: Paragigantocellular reticular nucleus, lateral part'],
        [979, '979: ccr: corpus callosum, rostrum'],
        [980, '980: PMd: Dorsal premammillary nucleus'],
        [981, '981: SSp-bfd1: Primary somatosensory area, barrel field, layer 1'],
        [982, '982: FC: Fasciola cinerea'],
        [983, '983: lfbs: lateral forebrain bundle system'],
        [984, '984: CENT3: Lobule III'],
        [985, '985: MOp: Primary motor area'],
        [986, '986: ccs: corpus callosum, splenium'],
        [987, '987: P-mot: Pons, motor related'],
        [988, '988: ECT5: Ectorhinal area/Layer 5'],
        [989, '989: FN: Fastigial nucleus'],
        [990, '990: AUDv4: Ventral auditory area, layer 4'],
        [991, '991: mfbs: medial forebrain bundle system'],
        [992, '992: CUL4: Lobule IV'],
        [993, '993: MOs: Secondary motor area'],
        [994, '994: cbt: corticobulbar tract'],
        [995, '995: PMR: Paramedian reticular nucleus'],
        [996, '996: AId1: Agranular insular area, dorsal part, layer 1'],
        [997, '997: root: root'],
        [998, '998: FS: Fundus of striatum'],
        [999, '999: ENTl2/3: Entorhinal area, lateral part, layer 2/3'],
        [1000, '1000: eps: extrapyramidal fiber systems'],
        [1001, '1001: CUL5: Lobule V'],
        [1002, '1002: AUDp: Primary auditory area'],
        [1003, '1003: cpt: corticopontine tract'],
        [1004, '1004: PMv: Ventral premammillary nucleus'],
        [1005, '1005: AUDp6b: Primary auditory area, layer 6b'],
        [1006, '1006: SSp-tr1: Primary somatosensory area, trunk, layer 1'],
        [1007, '1007: SIM: Simple lobule'],
        [1008, '1008: GENd: Geniculate group, dorsal thalamus'],
        [1009, '1009: fiber tracts: fiber tracts'],
        [1010, '1010: VISC4: Visceral area, layer 4'],
        [1011, '1011: AUDd: Dorsal auditory area'],
        [1012, '1012: crt: corticorubral tract'],
        [1014, '1014: GENv: Geniculate group, ventral thalamus'],
        [1015, '1015: ACAd5: Anterior cingulate area, dorsal part, layer 5'],
        [1016, '1016: onl: olfactory nerve layer of main olfactory bulb'],
        [1017, '1017: AN: Ansiform lobule'],
        [1018, '1018: AUDv: Ventral auditory area'],
        [1019, '1019: cstc: corticospinal tract, crossed'],
        [1020, '1020: PO: Posterior complex of the thalamus'],
        [1021, '1021: MOs6a: Secondary motor area, layer 6a'],
        [1022, '1022: GPe: Globus pallidus, external segment'],
        [1023, '1023: AUDv5: Ventral auditory area, layer 5'],
        [1024, '1024: grv: grooves'],
        [1025, '1025: PRM: Paramedian lobule'],
        [1026, '1026: SSp-ul6b: Primary somatosensory area, upper limb, layer 6b'],
        [1027, '1027: AUDpo: Posterior auditory area'],
        [1028, '1028: cstu: corticospinal tract, uncrossed'],
        [1029, '1029: POL: Posterior limiting nucleus of the thalamus'],
        [1030, '1030: SSp-ll1: Primary somatosensory area, lower limb, layer 1'],
        [1031, '1031: GPi: Globus pallidus, internal segment'],
        [1032, '1032: grv of CTX: grooves of the cerebral cortex'],
        [1033, '1033: COPY: Copula pyramidis'],
        [1034, '1034: TTd1: Taenia tecta, dorsal part, layer 1'],
        [1035, '1035: SSs4: Supplemental somatosensory area, layer 4'],
        [1036, '1036: cte: corticotectal tract'],
        [1037, '1037: POST: Postsubiculum'],
        [1038, '1038: SSp-bfd6a: Primary somatosensory area, barrel field, layer 6a'],
        [1039, '1039: GR: Gracile nucleus'],
        [1040, '1040: grv of CBX: grooves of the cerebellar cortex'],
        [1041, '1041: PFL: Paraflocculus'],
        [1042, '1042: TTd2: Taenia tecta, dorsal part, layer 2'],
        [1043, '1043: tspc: crossed tectospinal pathway'],
        [1044, '1044: PP: Peripeduncular nucleus'],
        [1045, '1045: ECT6b: Ectorhinal area/Layer 6b'],
        [1046, '1046: VISam6a: Anteromedial visual area, layer 6a'],
        [1047, '1047: SSp-bfd4: Primary somatosensory area, barrel field, layer 4'],
        [1048, '1048: GRN: Gigantocellular reticular nucleus'],
        [1049, '1049: FL: Flocculus'],
        [1050, '1050: TTd3: Taenia tecta, dorsal part, layer 3'],
        [1051, '1051: tspd: direct tectospinal pathway'],
        [1052, '1052: PPN: Pedunculopontine nucleus'],
        [1053, '1053: ACA2/3: Anterior cingulate area, layer 2/3'],
        [1054, '1054: ILA6a: Infralimbic area, layer 6a'],
        [1055, '1055: eg: endorhinal groove'],
        [1056, '1056: ANcr1: Crus 1'],
        [1057, '1057: GU: Gustatory areas'],
        [1058, '1058: VISC5: Visceral area, layer 5'],
        [1059, '1059: TTd4: Taenia tecta, dorsal part, layer 4'],
        [1060, '1060: dtd: doral tegmental decussation'],
        [1061, '1061: PPT: Posterior pretectal nucleus'],
        [1062, '1062: SSp-bfd6b: Primary somatosensory area, barrel field, layer 6b'],
        [1063, '1063: hf: hippocampal fissure'],
        [1064, '1064: ANcr2: Crus 2'],
        [1065, '1065: HB: Hindbrain'],
        [1066, '1066: VISam2/3: Anteromedial visual area, layer 2/3'],
        [1067, '1067: TTv1: Taenia tecta, ventral part, layer 1'],
        [1068, '1068: mfbst: dorsal thalamus related'],
        [1069, '1069: PPY: Parapyramidal nucleus'],
        [1070, '1070: SSp-bfd5: Primary somatosensory area, barrel field, layer 5'],
        [1071, '1071: rf: rhinal fissure'],
        [1072, '1072: MGd: Medial geniculate complex, dorsal part'],
        [1073, '1073: HEM: Hemispheric regions'],
        [1074, '1074: VISal1: Anterolateral visual area, layer 1'],
        [1075, '1075: TTv2: Taenia tecta, ventral part, layer 2'],
        [1076, '1076: cvb: efferent cochleovestibular bundle'],
        [1077, '1077: PR: Perireunensis nucleus'],
        [1078, '1078: ri: rhinal incisure'],
        [1079, '1079: MGv: Medial geniculate complex, ventral part'],
        [1080, '1080: HIP: Hippocampal region'],
        [1081, '1081: ILA6b: Infralimbic area, layer 6b'],
        [1082, '1082: TTv3: Taenia tecta, ventral part, layer 3'],
        [1083, '1083: mfbse: epithalamus related'],
        [1084, '1084: PRE: Presubiculum'],
        [1085, '1085: MOs6b: Secondary motor area, layer 6b'],
        [1086, '1086: SSp-tr4: Primary somatosensory area, trunk, layer 4'],
        [1087, '1087: pce: precentral fissure'],
        [1088, '1088: MGm: Medial geniculate complex, medial part'],
        [1089, '1089: HPF: Hippocampal formation'],
        [1090, '1090: SSs5: Supplemental somatosensory area, layer 5'],
        [1091, '1091: CUL4, 5: Lobules IV-V'],
        [1092, '1092: em: external medullary lamina of the thalamus'],
        [1093, '1093: PRNc: Pontine reticular nucleus, caudal part'],
        [1094, '1094: SSp-ll4: Primary somatosensory area, lower limb, layer 4'],
        [1095, '1095: pcf: preculminate fissure'],
        [1096, '1096: AMd: Anteromedial nucleus, dorsal part'],
        [1097, '1097: HY: Hypothalamus'],
        [1098, '1098: MDRNd: Medullary reticular nucleus, dorsal part'],
        [1099, '1099: fxs: fornix system'],
        [1100, '1100: PRT: Pretectal region'],
        [1101, '1101: AId5: Agranular insular area, dorsal part, layer 5'],
        [1102, '1102: SSp-m6a: Primary somatosensory area, mouth, layer 6a'],
        [1103, '1103: pri: primary fissure'],
        [1104, '1104: AMv: Anteromedial nucleus, ventral part'],
        [1105, '1105: IA: Intercalated amygdalar nucleus'],
        [1106, '1106: VISC2/3: Visceral area, layer 2/3'],
        [1107, '1107: MDRNv: Medullary reticular nucleus, ventral part'],
        [1108, '1108: ccg: genu of corpus callosum'],
        [1109, '1109: PS: Parastrial nucleus'],
        [1110, '1110: SUMl: Supramammillary nucleus, lateral part'],
        [1111, '1111: SSp-tr5: Primary somatosensory area, trunk, layer 5'],
        [1112, '1112: psf: posterior superior fissure'],
        [1113, '1113: IAD: Interanterodorsal nucleus of the thalamus'],
        [1114, '1114: VISal4: Anterolateral visual area, layer 4'],
        [1116, '1116: gVIIn: genu of the facial nerve'],
        [1117, '1117: P-sat: Pons, behavioral state related'],
        [1118, '1118: SUMm: Supramammillary nucleus, medial part'],
        [1119, '1119: ppf: prepyramidal fissure'],
        [1120, '1120: IAM: Interanteromedial nucleus of the thalamus'],
        [1121, '1121: ENTl1: Entorhinal area, lateral part, layer 1'],
        [1123, '1123: icp: inferior cerebellar peduncle'],
        [1124, '1124: PSCH: Suprachiasmatic preoptic nucleus'],
        [1125, '1125: ORBvl5: Orbital area, ventrolateral part, layer 5'],
        [1126, '1126: TMd: Tuberomammillary nucleus, dorsal part'],
        [1127, '1127: TEa2/3: Temporal association areas, layer 2/3'],
        [1128, '1128: SSp-ll5: Primary somatosensory area, lower limb, layer 5'],
        [1129, '1129: IB: Interbrain'],
        [1131, '1131: iVIIn: intermediate nerve'],
        [1132, '1132: P-sen: Pons, sensory related'],
        [1133, '1133: ENTmv5/6: Entorhinal area, medial part, ventral zone, layer 5/6'],
        [1139, '1139: NLOT3: Nucleus of the lateral olfactory tract, layer 3'],
        [1140, '1140: TR1: Postpiriform transition area, layers 1'],
        [1141, '1141: TR2: Postpiriform transition area, layers 2'],
        [1142, '1142: TR3: Postpiriform transition area, layers 3'],
        [1143, '1143: CBXgr: Cerebellar cortex, granular layer'],
        [1144, '1144: CBXmo: Cerebellar cortex, molecular layer'],
        [1145, '1145: CBXpu: Cerebellar cortex, Purkinje layer']
    ]);

}
